<template>
  <v-layout v-if="patient && user" class="patient-page" justify-start>
    <v-flex xs12>
      <PatientDemographicsPanel class="mb-4" />
      <patient-tag-cloud class="mb-4" />
      <threshold-settings class="mb-4" />

      <patient-notifications class="mb-4" />
      <chart-access-log />
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import HelperMixin from '@/core/mixins/HelperMixin'
import { mask } from 'vue-the-mask'
import bus from '@/core/helpers/bus'
import ThresholdSettings from '@/components/ThresholdSettings'
import PatientDemographicsPanel from '@/components/PatientDemographicsPanel'
import PatientTagCloud from '@/components/PatientTagCloud'
import PatientNotifications from '@/components/PatientNotifications'
import ChartAccessLog from '@/components/ChartAccessLog'
import get from 'lodash/get'

export default {
  mixins: [HelperMixin],
  directives: {
    mask,
  },
  data: () => ({
    fetchingLogs: false,
    redDoor: false,
    shouldSaveUpdates: false,
    mask: '###-###-####',
    zipMask: '#####',
    birthdateMask: '##/##/####',
    canEditInfo: true,
    genderOptions: ['MALE', 'FEMALE'],
    patientInput: {
      firstName: '',
      lastName: '',
      gender: '',
      birthdate: '',
      phone: '',
      address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: '',
      },
    },
    valid: false,
    nameRules: [v => !!v || 'Name is required'],
    birthdateRules: [v => !!v || 'Birthdate is required'],
    genderRules: [v => !!v || 'Gender is required'],
    streetRules: [v => !!v || 'Street Address is required'],
    cityRules: [v => !!v || 'City is required'],
    stateRules: [v => !!v || 'Valid State Required'],
    zipRules: [v => !!v || 'Zip Code is required'],
    patientPartnerIDs: [],
  }),
  components: {
    ChartAccessLog,
    ThresholdSettings,
    PatientDemographicsPanel,
    PatientNotifications,
    PatientTagCloud,
  },
  watch: {
    patient(val) {
      this.patientInput.firstName = val.firstName
      this.patientInput.lastName = val.lastName
      this.patientInput.gender = val.gender
      this.patientInput.birthdate = this.getBirthdateStringFromTimestamp(val.birthdate)
      this.patientInput.address.line1 = get(val, 'address.line1')
      this.patientInput.address.line2 = get(val, 'address.line2')
      this.patientInput.address.city = get(val, 'address.city')
      this.patientInput.address.state = get(val, 'address.state')
      this.patientInput.address.zip = get(val, 'address.zip')
      this.patientInput.phone = val.phone
      this.patientPartnerIDs = []
      if (val.partnerIDs) {
        val.partnerIDs.forEach(partner => {
          this.getPartner({ id: partner.partner }).then(data => {
            let partnerToAdd = data[0]
            partnerToAdd.id = partner.id
            this.patientPartnerIDs.push(partnerToAdd)
          })
        })
      }
    },
  },
  computed: {
    ...mapState('patient', ['patients', 'patient', 'files', 'patientEditingMode', 'patientLogs']),
    ...mapState('auth', ['rpmPartner', 'appTheme', 'user']),
    canUpdateValues() {
      if (this.patient && this.patient.permissions && this.patient.permissions['EDIT_INFO']) {
        return true
      }
      return false
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown || false
    },
  },
  methods: {
    ...mapActions('patient', ['search', 'getPatient', 'getFiles', 'updatePatient', 'getPartner', 'getChartActivity']),
    ...mapActions('auth', ['userActivity']),
    submitPatientUpdates(payload) {
      this.updatePatient({
        id: this.$route.params.id,
        payload: payload,
      }).then(() => {
        this.getPatient({ id: this.$route.params.id })
        bus.$emit('toast', { type: 'success', text: 'Patient Updated' })
        this.userActivity({ patient: this.$route.params.id, type: 'update_demographics' })
      })
    },
    ...mapMutations('patient', ['setPatientEditingMode']),
    validState(v) {
      let stateValidation = [
        'AL',
        'AK',
        'AS',
        'AZ',
        'AR',
        'CA',
        'CO',
        'CT',
        'DE',
        'DC',
        'FM',
        'FL',
        'GA',
        'GU',
        'HI',
        'ID',
        'IL',
        'IN',
        'IA',
        'KS',
        'KY',
        'LA',
        'ME',
        'MH',
        'MD',
        'MA',
        'MI',
        'MN',
        'MS',
        'MO',
        'MT',
        'NE',
        'NV',
        'NH',
        'NJ',
        'NM',
        'NY',
        'NC',
        'ND',
        'MP',
        'OH',
        'OK',
        'OR',
        'PW',
        'PA',
        'PR',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VT',
        'VI',
        'VA',
        'WA',
        'WV',
        'WI',
        'WY',
      ]
      return stateValidation.includes(v)
    },
    submitUpdates() {
      this.shouldSaveUpdates = false
      if (this.valid) {
        const patientUpdated = {}

        patientUpdated.firstName = this.patientInput.firstName
        patientUpdated.lastName = this.patientInput.lastName
        patientUpdated.gender = this.patientInput.gender
        patientUpdated.address = this.patientInput.address
        if (this.patientInput.phone) {
          patientUpdated.phone = parseInt(this.patientInput.phone.replace(/[^0-9]/g, ''))
        }
        patientUpdated.birthdate = this.birthdateToTimestamp(this.patientInput.birthdate)

        this.updatePatient({
          id: this.patient._id,
          payload: patientUpdated,
        }).then(() => {
          this.setPatientEditingMode(false)
          this.getPatient({ id: this.patient._id })
          bus.$emit('toast', { type: 'success', text: 'Patient Updated' })
        })
      } else {
        bus.$emit('toast', { type: 'error', text: 'Missing Information' })
      }
    },
  },
  mounted() {
    if (this.$route.query.redDoor) {
      this.redDoor = true
    }
    if (!this.patient) {
      this.getPatient({ id: this.$route.params.id })
    }
  },
}
</script>

<style></style>
