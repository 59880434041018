<template>
  <v-dialog v-model="show" :max-width="options.maxWidth" 
  :transition="transition"
  scrollable
  :fullscreen="$vuetify.breakpoint.xsOnly">
  <v-card>
        <v-toolbar flat color="toolbars">
            <v-toolbar-title>Add Alert Recipient</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
            <v-card-text class="mt-5 pt-2" >
            <v-form ref="emailForm" v-model="valid">
      
                <v-text-field
                      outlined flat
                      v-model="emailAddress"
                      required
                      label="Email Address"
                      :rules="emailRules"
                    ></v-text-field>
                     </v-form>
                     <v-layout row class="mx-1 mt-2">
                       <span style="text-align: justify">
                       Recipients will be notified by email every time a threshold is breached. The value of the threshold breach will be included in the 
                       body of the email but no patient identifying information will be present. A link to the full chart will be included in the email. 
                       Please ensure that the recipient has access to the chart.
                       </span>
                     </v-layout>
            </v-card-text>

<v-card-actions>
  <v-btn depressed  @click="close">Cancel</v-btn>
  <v-spacer></v-spacer>
          <v-btn depressed color="primary" dark @click="addUser()">Add Recipient</v-btn>
          
</v-card-actions>
      </v-card>

    </v-dialog>
</template>
<script>
import DialogMixin from '@/core/mixins/DialogMixin'
import HelperMixin from '@/core/mixins/HelperMixin'
import { mapState, mapActions } from 'vuex'
import bus from '@/core/helpers/bus'

export default {
  mixins: [DialogMixin, HelperMixin],
  components: {
  },
  data() {
    return {
      emailAddress: '',
      valid: false,

      nameRules: [
        v => !!v || 'Required'
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v =>
          this.validateEmail(v) ||
          'E-mail must be valid'
      ],
      inviteBulletPoints: [ {icon: 'mdi-email', title: 'Invitation Email', text: 'A secure invite link is sent to the indicated email address.'}, {icon: 'mdi-lock', title: 'Authenticated Access', text: 'The recipient must follow the link and sign in or sign up to be granted access. Only the indicated email can be used.'}, {icon: 'mdi-calendar-clock', title: 'Link Expires in 72 Hours', text: 'The secure link embedded in the email will expire within 72 hours if unused.'} ]
    }
  },
  computed: {
    ...mapState('patient', ['patient']),
    transition() {
      return this.$vuetify.breakpoint.xsOnly
        ? 'dialog-bottom-transition'
        : 'scale-transition'
    },
    emailValid() {
      if (this.validateEmail(this.emailAddress)) {
        return true
      }
      return false
    }
  },
  watch: {
  },
  mounted() {
    this.$on('open', this.onOpen)
    this.$on('close', this.OnClose)
  },
  methods: {
    ...mapActions('provider', ['shareChart']),
    ...mapActions('auth', ['userExists', 'addUserToPartner']),
    toast(type, text) {
      bus.$emit('toast', { type, text })
    },
    checkUser() {
    },
    addUser() {
      let val = this.emailAddress
      val = val.trim()
      if (this.validateEmail(val)) {
        this.$emit('addEmail', this.emailAddress)
        this.close()
      } else {
        this.toast('error', 'Invalid Email')
      }
    },
    onClose() {
      this.$refs.emailForm.reset()
      this.$refs.emailForm.resetValidation()
    },
    close() {
      this.emailAddress = ''
      this.cancel()
    },
    onOpen() {
    }
  }
}
</script>
