<template>
  <v-card :loading="fetchingLogs" outlined flat>
    <v-toolbar flat color="transparent">
      <v-toolbar-title>
        Chart Access Log
      </v-toolbar-title>
    </v-toolbar>
    <v-divider />
    <v-simple-table height="400" fixed-header style="width: 100%;">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Access</th>
            <th class="text-right">Timestamp</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in patientLogs" :key="index">
            <td class="mt-2 mb-2">
              {{ item.userDisplayName }} <span style="opacity:0.7">{{ item.description }}</span>
            </td>
            <td style="max-width: 200px; width: 200px; text-align: right">{{ item.timestampString }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      fetchingLogs: false,
    }
  },
  computed: {
    ...mapState('patient', ['patients', 'patient', 'files', 'patientEditingMode', 'patientLogs']),
    ...mapState('auth', ['rpmPartner', 'appTheme', 'user']),
  },
  methods: {
    ...mapActions('patient', ['search', 'getPatient', 'getFiles', 'updatePatient', 'getChartActivity']),
    ...mapActions('auth', ['userActivity']),
  },
  mounted() {
    if (this.patient) {
      this.getChartActivity(this.$route.params.id).then(() => {
        this.fetchingLogs = false
      })
    }
  },
}
</script>

<style></style>
