<template>
  <v-card flat rounded="lg" outlined :loading="working" class="flex" min-height="200">
    <v-card flat color="transparent" class="pa-4 mb-1">
      <v-row align="center" justify="space-between" class="mb-2" no-gutters>
        <span class="font-weight-bold text-h6">
          Patient Demographics
        </span>
        <v-btn depressed :disabled="!updatesMade" dark color="green" @click="updatePatientAndMarkAsReady()"
          >Save Updates</v-btn
        >
      </v-row>
    </v-card>
    <v-form ref="patientDemographics">
      <v-row class="mx-4" no-gutters>
        <v-col>
          <v-row no-gutters>
            <v-text-field
              class="ma-1"
              outlined
              v-model="patientInput.firstName"
              :rules="nameRules"
              label="First Name"
              required
              dense
              @keyup="updatesMade = true"
            ></v-text-field>
            <v-text-field
              class="ma-1"
              outlined
              v-model="patientInput.lastName"
              :rules="nameRules"
              label="Last Name"
              required
              dense
              @keyup="updatesMade = true"
            ></v-text-field>
          </v-row>
          <v-row no-gutters>
            <v-col xs="6" sm="6" md="5" lg="4">
              <v-select
                class="ma-1"
                outlined
                v-model="patientInput.gender"
                :rules="genderRules"
                :items="genderOptions"
                label="Sex"
                required
                dense
                @keyup="updatesMade = true"
              ></v-select>
            </v-col>
            <v-col xs="6" sm="6" md="5" lg="4">
              <v-text-field
                class="ma-1"
                outlined
                v-mask="birthdateMask"
                :rules="birthdateRules"
                v-model="patientInput.birthdate"
                label="Date of Birth"
                required
                dense
                hint="mm/dd/yyyy"
                persistent-hint
                @keyup="updatesMade = true"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-text-field
              class="ma-1"
              outlined
              v-model="patientInput.email"
              label="Patient Email"
              dense
              @keyup="updatesMade = true"
            ></v-text-field>
          </v-row>
          <v-row no-gutters>
            <v-col xs="12" md="7" lg="6">
              <v-text-field
                class="ma-1"
                outlined
                v-model="patientInput.primaryPhone"
                label="Primary Phone"
                v-mask="phoneMask"
                dense
                @keyup="updatesMade = true"
                persistent-hint
              >
              </v-text-field>
            </v-col>
            <v-col xs="12" md="7" lg="6">
              <v-text-field
                class="ma-1"
                outlined
                v-model="patientInput.smsPhone"
                label="Phone for Messaging"
                v-mask="phoneMask"
                dense
                @keyup="updatesMade = true"
                hint="This number used for messaging"
                persistent-hint
              >
                <v-icon color="primary" small slot="append">mdi-message-outline</v-icon>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col xs="12" md="7" lg="6">
              <v-text-field
                disabled
                dense
                v-if="patient.ownerPatientID"
                class="ma-1"
                outlined
                readonly
                v-model="patient.ownerPatientID"
                label="Organization Patient ID"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row no-gutters>
            <v-text-field
              class="ma-1"
              outlined
              v-model="patientInput.address.line1"
              :rules="patientIDRules"
              label="Street Address"
              required
              dense
              @keyup="updatesMade = true"
            ></v-text-field>
          </v-row>
          <v-row no-gutters>
            <v-text-field
              class="ma-1"
              outlined
              v-model="patientInput.address.line2"
              label="Street Address Line 2"
              dense
              @keyup="updatesMade = true"
            ></v-text-field>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                class="ma-1"
                outlined
                :rules="patientIDRules"
                v-model="patientInput.address.city"
                label="City"
                required
                dense
                @keyup="updatesMade = true"
              ></v-text-field>
            </v-col>
            <v-col xs="6" sm="6" md="5" lg="4">
              <v-select
                class="ma-1"
                outlined
                :rules="birthdateRules"
                v-model="patientInput.address.state"
                label="State"
                :items="stateList"
                required
                dense
                @change="updatesMade = true"
              ></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col xs="12" sm="8" md="5" lg="4">
              <v-text-field
                class="ma-1"
                outlined
                :rules="patientIDRules"
                v-model="patientInput.address.zip"
                v-mask="zipMask"
                label="Zip"
                required
                dense
                @keyup="updatesMade = true"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import HelperMixin from '@/core/mixins/HelperMixin'
import bus from '@/core/helpers/bus'
import { mask } from 'vue-the-mask'
import clone from 'lodash/clone'

export default {
  name: 'ChartCreationDialog',
  mixins: [HelperMixin],
  components: {},
  directives: {
    mask,
  },
  data() {
    return {
      updatesMade: false,
      working: false,
      shouldSaveUpdates: false,
      phoneMask: '###-###-####',
      zipMask: '#####',
      birthdateMask: '##/##/####',
      canEditInfo: true,
      genderOptions: ['MALE', 'FEMALE'],
      patientInput: {
        firstName: '',
        lastName: '',
        gender: '',
        birthdate: '',
        address: {
          line1: null,
          line2: null,
          city: null,
          state: null,
          zip: null,
        },
        email: '',
      },
      workPhoneForSMS: false,
      primaryPhoneForSMS: false,
      otherPhoneForSMS: false,
      patientReady: false,
      valid: false,
      nameRules: [v => !!v || 'Name is required'],
      birthdateRules: [v => !!v || 'Birthdate is required'],
      genderRules: [v => !!v || 'Sex is required'],
      patientIDRules: [v => !!v || 'Required'],
      partnerPatientIDInput: null,
      patientIDForAssigningToDevice: null,
      chartAdministrator: null,
      requirePartnerPatientID: false,
      possibleDuplicates: [],
      patientData: null,
      updatingDevice: false,
      existingPatientID: null,
      showForm: false,
      showDuplicateButton: false,
      defaultOptions: {
        okLabel: 'Confirm',
        cancelLabel: 'Cancel',
        showOk: true,
        showCancel: false,
      },
    }
  },
  methods: {
    ...mapActions('patient', ['getPatientInfo']),
    ...mapActions('device', ['updateDevice']),
    ...mapActions('patient', [
      'getPatient',
      'checkDuplicateCharts',
      'getPatientIDFromPartnerID',
      'updatePatient',
    ]),
    ...mapMutations('patient', ['setPatient']),
    ...mapActions('patient', ['clearPatient']),
    ...mapActions('auth', ['userActivity']),
    showPossibleMatches() {
      this.$refs.possibleMatchesDialog.open({
        possibleMatches: this.possibleDuplicates,
      })
    },
    updated() {
      this.updatesMade = true
    },
    updatePatientAndMarkAsReady() {
      if (this.$refs.patientDemographics.validate()) {
        // patch the patient.
        let patientHere = clone(this.patientInput)

        if (patientHere.address) {
          if (!patientHere.address.line2) {
            delete patientHere.address.line2
          }
          if (!patientHere.address.line1 && !patientHere.address.state) {
            delete patientHere.address
          }
        }

        patientHere.birthdate = this.humanReadableDateToISO(this.patientInput.birthdate)

        // only include items that are not null

        let keys = Object.keys(patientHere)
        keys.forEach(key => {
          if (!patientHere[key]) {
            delete patientHere[key]
          }
        })

        patientHere.rpmSetupComplete = true
        this.updatePatient({ id: this.$route.params.id, payload: patientHere })
          .then(patient => {
            // set patient
            this.setPatient(patient)
            this.parsePatient()
            this.updatesMade = false
            bus.$emit('toast', { type: 'success', text: 'Information Updated' })
          })
          .catch(error => {
            console.log(error)
            bus.$emit('toast', { type: 'error', text: 'Internal Error' })
          })
      }
    },
    parsePatient() {
      if (this.patient) {
        this.patientInput.firstName = this.patient.firstName
        this.patientInput.lastName = this.patient.lastName
        this.patientInput.gender = this.patient.gender
        this.patientInput.smsPhone = this.patient.smsPhone
        this.patientInput.workPhone = this.patient.workPhone
        this.patientInput.otherPhone = this.patient.otherPhone
        this.patientInput.phoneForSMS = this.patient.phoneForSMS
        this.patientInput.primaryPhone = this.patient.primaryPhone
        this.$nextTick(() => (this.patientInput.birthdate = this.isoToDisplayDate(this.patient.birthdate)))
        //this.patientInput.birthdate = '01/01/1954'
        this.partnerPatientIDInput = this.patient.ownerPatientID
        this.patientInput.email = this.patient.email
        if (this.patient.address) {
          this.patientInput.address.line1 = this.patient.address.line1
          this.patientInput.address.line2 = this.patient.address.line2
          this.patientInput.address.city = this.patient.address.city
          this.patientInput.address.state = this.patient.address.state
          this.patientInput.address.zip = this.patient.address.zip
        }
      }
    },
  },
  watch: {
    patient() {
      this.parsePatient()
    },
  },
  computed: {
    ...mapState('auth', ['partner', 'user']),
    ...mapState('patient', ['patient']),
  },
  mounted() {
    if (this.patient) {
      this.parsePatient()
    }
  },
}
</script>
