<template>
  <v-card :loading="loadingThresholds || updatingPatientThresholds" outlined flat tile>
    <add-threshold-alert-recipient @addEmail="addRecipient" ref="addAlertNotificationRecipient" />
    <v-toolbar flat>
      <v-toolbar-title>Threshold Alert Notifications</v-toolbar-title>
      <v-spacer />
      <v-btn color="green" dark depressed @click="addThreshold">
        <v-icon class="mr-2" left>mdi-email-plus</v-icon>Add Recipient
      </v-btn>
    </v-toolbar>
    <v-card-text class="pt-0"
      >Threshold alert notifications are sent by email whenever a threshold is breached. The value that breached the
      threshold is included in the body of the email along with a link to the chart. No identifiable patient information
      is sent in the email. Please ensure that the recipient has access to the patient's chart either as a viewer or an
      administrator before they are added to the list below.</v-card-text
    >
    <v-divider></v-divider>

    <v-simple-table :key="boomKey" style="width: 100%;">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Recipient</th>
            <th class="text-left">Remove</th>
          </tr>
        </thead>
        <tbody v-if="patient.alertNotifications.length > 0">
          <tr v-for="(item, index) in patient.alertNotifications" :key="index" :class="alternatingColors(index)">
            <td class="mt-2 mb-2">{{ item.email }}</td>
            <td style="max-width: 80px; width: 80px;">
              <v-layout row justify-center>
                <v-btn @click="removeRecipient(item)" color="red" icon>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-layout>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-layout
      class="mx-0 mt-4 mb-4"
      v-if="patient.alertNotifications.length === 0"
      style="min-width: 100%; opacity: 0.4"
      row
      justify-center
      >No Recipients Set</v-layout
    >
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AddThresholdAlertRecipient from '@/components/dialogs/AddThresholdAlertRecipient'
import HelperMixin from '@/core/mixins/HelperMixin'

export default {
  mixins: [HelperMixin],
  data() {
    return {
      boomKey: 0,
      selectedStartDate: 1,
      autoCounting: false,
      partnerObject: {},
      rulesTable: 0,
      loadingThresholds: false,
      rulesArray: [],
    }
  },
  components: {
    AddThresholdAlertRecipient,
  },
  computed: {
    ...mapState('auth', ['partner']),
    ...mapState('patient', ['patient', 'updatingPatientThresholds']),
    partnerMode() {
      if (this.partner && this.$route.name === 'Partner') {
        return true
      }
      return false
    },
    rpmThresholds() {
      return this.partner.thresholds
    },
    description() {
      if (this.partnerMode) {
        return 'Global threshold alert rules are applied to the entire patient population. If a value is above or below the indicated level, the alert will be applied. Threshold values set on the patient level will take precedence over these global values.'
      }
      return 'Threshold alert rules are applied to the patient monitoring data entering the chart. If a value is above or below the indicated level, the alert will be applied. Threshold values set on the patient level will take precedence over global values.'
    },
    descriptionTitle() {
      if (this.partnerMode) {
        return 'Global Threshold Alert Rules'
      }
      return 'Threshold Alert Rules'
    },
  },
  methods: {
    ...mapActions('auth', ['updatePartnerSettings', 'setPartnerThresholdSettings']),
    ...mapActions('patient', ['updatePatientNotifications']),
    addThreshold() {
      this.$refs.addAlertNotificationRecipient.open()
    },
    removeRecipient(item) {
      let index = this.patient.alertNotifications.indexOf(item)
      if (index > -1) {
        this.patient.alertNotifications.splice(index, 1)
      }
      this.updatePatientNotifications({
        patientID: this.patient._id,
        notificationList: this.patient.alertNotifications,
      })
    },
    addRecipient(email) {
      this.patient.alertNotifications.push({ email: email })
      this.updatePatientNotifications({
        patientID: this.patient._id,
        notificationList: this.patient.alertNotifications,
      })
    },
  },
  mounted() {},
}
</script>

<style></style>
